import React, { PropsWithChildren, ReactElement } from "react";

import {
  PROGRESS_LOCATION as CarouselProgress,
  TRANSITION as CarouselTransition,
  Carousel,
  THEME as CarouselTheme
} from "@chweb/commonui";

export type InlineCarouselProps = PropsWithChildren;

function InlineCarousel ({ children }: InlineCarouselProps): ReactElement {
  const slides = React.Children.toArray(children).filter(c => typeof c !== "string");
  const slidesWrapped = slides.map((s, i) =>
    <div
      style = {{ width: "500px", margin: "auto" }}
      key = { i.toString() }
    >
      { s }
    </div>
  );
  return (
    <div style = {{ width: "100%" }}>
      <Carousel
        disableArrows
        progressLocation = { CarouselProgress.BOTTOM }
        transition = { CarouselTransition.SLIDE }
        timeOutSecs = { 10 }
        theme = { CarouselTheme.BROWN }
      >
        { slidesWrapped }
      </Carousel>
    </div>
  );
}

export default InlineCarousel;
