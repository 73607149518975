import React, { ReactElement } from 'react';
import { graphql } from 'gatsby';
import RehypeReact from 'rehype-react';
import unified from 'unified';
import { Node } from 'unist';

import Layout from '../components/layout';
import WithMargins from '../components/with-margins';

import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { pageInfoByLocale } from '../queries/page-info-helpers';
import { getLocalizedValue } from '../components/locale-context';
import InlineCarousel, { InlineCarouselProps } from '../components/inline-carousel';
import InlineTiles, { InlineTilesProps } from '../components/inline-tiles';
import { InterractiveSection } from "@chweb/commonui";
import { PageInfoData } from '../queries/fragment-page-info';

// @ts-expect-error some weird RehypeReact typing issue
const astProcessor = unified().use(RehypeReact, {
  createElement: React.createElement,
  components: {
    slideshow: (props: InlineCarouselProps) => <InlineCarousel {...props} />,
    tiles: (props: InlineTilesProps) => <InlineTiles {...props} />
  }
});

export const renderAst = (ast: Node): JSX.Element => {
  return (astProcessor.stringify(ast) as unknown) as JSX.Element
}

interface BasicPageProps {
  data: BasicTemplateData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo 
}

export default function BasicPage ({ data, pageContext, location }: BasicPageProps): ReactElement {
  const locale = pageContext.locale;

  // this page should be treated as an article
  const seo = PageSEO.fromLocation(location);
  seo.isArticle = true;
  seo.description = data.md.excerpt;

  const pageInfo = getLocalizedValue(pageInfoByLocale(data.md.frontmatter.pageId), locale);

  const title = pageInfo.title;
  return (
    <Layout locale = { pageContext.locale } location={location} title = { title } seo = { seo }>
      <WithMargins margin="5%">
        {
          data.md.frontmatter.formalTitle
            ? <InterractiveSection title = { title }><div>{ renderAst(data.md.htmlAst) }</div></InterractiveSection>
            : <div><h1 className= "cptitle cpcolor-text-brown"> { title } </h1>{ renderAst(data.md.htmlAst) }</div>
        }
      </WithMargins>
    </Layout>
  );
}

interface BasicTemplateData {
  md: {
    frontmatter: {
      pageId: PageInfoData,
      formalTitle: string
    },
    excerpt: string,
    htmlAst: Node
  }
}

export const basicQuery = graphql`
query(
  $slug: String!
) {
    md: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        pageId {
          ...PageInfo
        }
        formalTitle
      }
      excerpt(pruneLength: 250)
      htmlAst
    }
}`;
